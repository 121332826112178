import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import InputFormik from '../../../components/InputFormik';
import SelectFormik from '../../../components/SelectFormik';
import PercentFormat from '../../../components/PercentFormat';
import { useWizardContext } from '../../../components/FormWizard/WizardContext';
import messages from '../messages';
import MonthYearfield from '../../../components/MonthYearField';
import { useAppContext } from '../../App/AppContext';
import { getNumbersAndDecimalsOnly } from '../../../utils/utils';
import ListItemCheckboxFormik from '../../../components/ListItemCheckboxFormik';

const OutputDesign = () => {
  const { values, setFieldValue } = useWizardContext();
  const { settings } = useAppContext();
  const intl = useIntl();
  const [hasDistributionAmounts, setHasDistributionAmounts] = useState(false);
  const [
    israngeOfPossibleOutcomesDisabled,
    setIsrangeOfPossibleOutcomesDisabled,
  ] = useState(false);

  useEffect(() => {
    if (
      values.interestRateAndIncome.hasIncome &&
      values.interestRateAndIncome.incomeLoanType[0].loanType === 'Alternative'
    ) {
      setFieldValue('illustrationIndicators.rangeOfPossibleOutcomes', true);
      setIsrangeOfPossibleOutcomesDisabled(true);
    } else {
      setIsrangeOfPossibleOutcomesDisabled(false);
    }
  }, [values.interestRateAndIncome.incomeLoanType]);

  useEffect(() => {
    let hasDistribution = false;
    for (
      let x = 0;
      x < values.interestRateAndIncome.incomeDistributionAmounts.length;
      x++
    ) {
      const unformattedValue = getNumbersAndDecimalsOnly(
        values.interestRateAndIncome.incomeDistributionAmounts[x]
          .distributionAmount,
      );
      const distributionAmount = Number(unformattedValue);
      hasDistribution = distributionAmount !== 0 || unformattedValue === '';
      setHasDistributionAmounts(hasDistribution);
      if (hasDistribution) break;
    }
    if (!hasDistribution) {
      setTimeout(() => {
        setFieldValue('illustrationIndicators.iulAsRothIRASupplement', false);
        setFieldValue(
          'illustrationIndicators.insuranceBasedIncomeSolution',
          false,
        );
      }, 0);
    }
  }, [values.interestRateAndIncome.incomeDistributionAmounts, setFieldValue]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputFormik
            name="presentedBy"
            label={intl.formatMessage(messages.presentedBy)}
            maxLength={100}
          />
        </Grid>
        <Grid item xs={12}>
          <InputFormik
            name="illustrationTitle"
            label={intl.formatMessage(messages.illustrationTitle)}
            maxLength={100}
          />
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItemCheckboxFormik
              name="illustrationIndicators.indexInterestStrategyInformation"
              label={intl.formatMessage(messages.indexInterestStrategyInformation)}
            />
            <ListItemCheckboxFormik
              disabled
              name="illustrationIndicators.generalLedger"
              label={intl.formatMessage(messages.generalLedger)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.annualCostSummary"
              label={intl.formatMessage(messages.annualCostSummary)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.internalRateOfReturnReport"
              label={intl.formatMessage(messages.internalRateOfReturnReport)}
            />
            <ListItemCheckboxFormik
              disabled
              name="illustrationIndicators.premiumSummary"
              label={intl.formatMessage(messages.premiumSummary)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.nationwideFinancialStrength"
              label={intl.formatMessage(messages.nationwideFinancialStrength)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.valueOfLifeInsuranceReport"
              label={intl.formatMessage(messages.valueOfLifeInsuranceReport)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.occReport"
              label={intl.formatMessage(messages.occReport)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.outlayTaxReport"
              label={intl.formatMessage(messages.outlayTaxReport)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.surrenderChargeReport"
              label={intl.formatMessage(messages.surrenderChargeReport)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.monthlyCostSummary"
              label={intl.formatMessage(messages.monthlyCostSummary)}
            />
            {values.illustrationIndicators.monthlyCostSummary && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={6} lg={5}>
                    <SelectFormik
                      name="illustrationIndicators.whichIllustration"
                      label={intl.formatMessage(messages.whichIllustration)}
                      options={settings.whichIllustrationList}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <InputFormik
                      name="illustrationIndicators.yearsForMonthlyOutput"
                      label={intl.formatMessage(messages.yearsForMonthlyOutput)}
                      numberFormatCustom={MonthYearfield}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
            <ListItemCheckboxFormik
              name="illustrationIndicators.monthlyCostOfInsuranceReport"
              label={intl.formatMessage(messages.monthlyCostOfInsuranceReport)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.costAndBenefitReport"
              label={intl.formatMessage(messages.costAndBenefitReport)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.netCostPerDollar"
              label={intl.formatMessage(messages.netCostPerDollar)}
            />
            {values.illustrationIndicators.netCostPerDollar && (
              <ListItem className="list-container">
                <Grid container className="list-container" spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <InputFormik
                      name="illustrationIndicators.costOfMoney"
                      label={intl.formatMessage(messages.costOfMoney)}
                      numberFormatCustom={PercentFormat}
                      decimal={2}
                      maxLength={6}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
            <ListItemCheckboxFormik
              name="illustrationIndicators.rangeOfPossibleOutcomes"
              label={intl.formatMessage(messages.rangeOfPossibleOutcomes)}
              disabled={israngeOfPossibleOutcomesDisabled}
            />
          </List>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className="sub-title">
          <Typography variant="h2" color="textSecondary">
            {intl.formatMessage(messages.personalizedSalesIdeas)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItemCheckboxFormik
              disabled={!hasDistributionAmounts}
              name="illustrationIndicators.iulAsRothIRASupplement"
              label={intl.formatMessage(messages.iulAsRothIRASupplement)}
            />
            <ListItemCheckboxFormik
              name="illustrationIndicators.insuranceBasedIncomeSolution"
              label={intl.formatMessage(messages.insuranceBasedIncomeSolution)}
              disabled={!hasDistributionAmounts}
            />
          </List>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OutputDesign;
