import React, { useState, useEffect } from 'react';
import { useIntl, FormattedHTMLMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import messages from './messages';
import FormWizard from '../../components/FormWizard';
import ButtonProgress from '../../components/Button/ButtonProgress';
import Hint from '../../components/Hint';
import NotificationMessages from '../../components/NotificationMessages';
import InsuredStep from './steps/Insured';
import FaceAmount from './steps/FaceAmount';
import InterestRate from './steps/InterestRate';
import Riders from './steps/Riders';
import OutputDesign from './steps/OutputDesign';
import { useAppContext } from '../App/AppContext';
import {
    FASE_AMOUNT_PREMIUM,
    HTTP_STATUS,
    POPULATE_SOURCE_KEY,
} from '../../constants';
import { saveIllustration, getIllustration, getComparisonIllustration, cancelComparisonIllustrationRequest, validateQueryStringToken } from '../../utils/api';
import { PopupWarningText } from '../../components/PopupWarningText/index';
import { isMobileOnly } from 'react-device-detect';
import AuthHelper from '../../utils/auth';
import { MapValuesToApi, MapDataValuesToState, paramsToObject } from './utils';
import {
    RESULT_MESSAGES_SUCCESS,
    RESULT_MESSAGES_ERROR,
    SERVER_ERROR_MESSAGE,
    REQUEST_ERROR_MESSAGE
} from './constants';
import MinNonMECComparison from './components/Tables/MinNonMECComparison';
import {
    InsuredValidations,
    FaceAmountValidations,
    InterestRateValidations,
    RidersValidations,
    OutputDesignValidations,
} from './validators';
import { START_VALUES } from './startValues' 
import PropTypes from 'prop-types';

const auth = new AuthHelper();
const isAuthenticated = auth.isAuthenticated();

const stepTitles = [
    'Insured',
    'Face Amount & Premium',
    'Interest Rate & Income',
    'Riders',
    'Output Design',
];


const startValues = START_VALUES;

const cleanAllocation = indexInfo => 
  indexInfo.map((indexItem) => ({
    key: indexItem.key,
    allocation: indexItem.allocation != '' ? +indexItem.allocation : ''
  })); 


let params = window.location.search;
if(params.includes('input') && isAuthenticated) {
  const {input} = paramsToObject(new URLSearchParams(params).entries());
  const paramsStored = localStorage.getItem(POPULATE_SOURCE_KEY);
  if(paramsStored && !params.includes('authToken')) {
     params = paramsStored
  }
  
  if (input) {
    const {
      state,
      indexInfo,
      authToken,
      presentedBy
    } = paramsToObject(new URLSearchParams(input).entries());
    
    if (authToken) {
      startValues.prepopulateData = true;
      localStorage.setItem(POPULATE_SOURCE_KEY, params);

      validateQueryStringToken({ token: authToken })
      .then(data => {
        const { data: isValidToken } = data;
        if(isValidToken) {
          startValues.insured.issueState = state || undefined;
          if(indexInfo) {
            startValues.interestRateAndIncome.premiumAllocation[0].indexInfo = cleanAllocation(JSON.parse(indexInfo));
          }
          startValues.presentedBy = presentedBy || '';
        }
        localStorage.removeItem(POPULATE_SOURCE_KEY);
      }); 
     }
  }
}


const comparasionDataInitValues = {
    MinNonMec : null, 
    BalanceSolve: null, 
    SpecAmnt: null
}
const Illustration = ({ match, history }) => {
    const intl = useIntl();
    const { settings, advisorDetails, setCurrentProduct } = useAppContext();
    // Server result messages
    const [isTablePopupOpen, setIsTablePopupOpen] = useState(false);
    const [isbuttonWasClicked, setIsbuttonWasClicked] = useState(false);
    const [serverResultMessages, setServerResultMessages] = useState();
    const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [statusResultType, setStatusResultType] = useState('');
    const [illustrationId, setIllustrationId] = useState(match.params.id);
    const [initialValues, setInitialValues] = useState(startValues);
    const [comparisonData, setComparisonData] = useState(comparasionDataInitValues);
    const [radioButtonValue, setRadioButtonValue] = useState('');
    const [illustrationValues, setIllustrationValues] = useState({});
    const [specifiedAmountValue, setSpecifiedAmountValue] = useState(null);
    const [specAmountGetted, setSpecAmountGetted] = useState(false);
    const [minNonMecErrors, setMinNonMecErrors] = useState(0);
    const auth = new AuthHelper();

    const totalSpecifiedAmountCleanObject = {
        increase: '',
        max: '',
        specifiedAmountFrom: '1',
        specifiedAmountThru: 'Max',
        specifiedAmountValue: '',
        step: ''
    };

    useEffect(() => {
      if(initialValues.prepopulateData) {
        window.history.pushState({}, document.title, '/');
      }
    },[initialValues.prepopulateData]);
    
    useEffect(() => {
        if (illustrationId) {
            getIllustration(illustrationId)
                .then(result => {
                    var dt = MapDataValuesToState(result.data, startValues, settings);
                    var createdOnUtc = result.data.createdOnUtc;
                    setCurrentProduct(dt.product);
                    setInitialValues({ ...dt, illustrationId, createdOnUtc });
                })
                .catch(() => {
                    history.push('/NotFound');
                });
        }
  }, [history, illustrationId]);

    // Set indexInfo object structure to initialValues
    useEffect(() => {
        if (settings && settings.premiumAllocations) {
            const oneYearPremiumAllocationFiftyFiftyIndex =
                '1-Yr JP Morgan Mozaic II / 1-Yr NYSE Zebra Edge';
            const twoYearsPremiumAllocationFiftyFiftyIndex =
            '2-Yr JP Morgan Mozaic II / 2-Yr NYSE Zebra Edge';
          const oneYearPremiumAllocationFiftyFiftyGoldman =
            '1-Yr Capped S&P 500 / 1-Yr High Par 5 Goldman Sachs New Horizons';

          const premiumAllocations = settings.premiumAllocations.filter(
            item =>
              item.value !== oneYearPremiumAllocationFiftyFiftyIndex &&
              item.value !== twoYearsPremiumAllocationFiftyFiftyIndex &&
              item.value !== oneYearPremiumAllocationFiftyFiftyGoldman,
            );

            const defaultIndexInfoValues = premiumAllocations.map(
                (indexItem) => ({
                    key: indexItem.value,
                    allocation:
                      indexItem.value === '1-Yr Capped S&P 500' ||
                      indexItem.value === '1-Yr High Par 5 Goldman Sachs New Horizons'
                            ? 50
                            : '',
                }),
            );
            if(!startValues.prepopulateData) {
              initialValues.interestRateAndIncome.premiumAllocation[0].indexInfo = defaultIndexInfoValues;
            } 
        }

        if (settings && settings.maturedSegmentAllocations) {
            const defaultIndex = 'Reallocate to Matured Segment Strategy';
            const defaultIndexInfoValues = settings.maturedSegmentAllocations.map(
                (indexItem) => ({
                    key: indexItem.value,
                    allocation: indexItem.value === defaultIndex ? 100 : '',
                }),
            );
              initialValues.interestRateAndIncome.maturedSegmentAllocation[0].indexInfo = defaultIndexInfoValues;
        }
        if (advisorDetails) {
            initialValues.presentedBy = advisorDetails.preparedBy;
        }
    }, [settings, advisorDetails]);

    const handleShowNotification = () => {
        setIsNotificationDisplayed(false);
    };

    const ValidateSavePopup = () => {
        setIsbuttonWasClicked(true)
        setIsTablePopupOpen(false)
        return
    }
    const ValidateCancelPopup = () => {
        setIsTablePopupOpen(false);
        setSpecAmountGetted(false);
        setRadioButtonValue('')
        delete comparisonData?.SpecAmnt;
        return;
    }
    const cleanRidersToSimulate = (riders) => {
      const ridersClone = _.cloneDeep(riders);
      ridersClone.conditionalReturnOfPremium = false;
      ridersClone.surrenderValueEnhancementRider = false;
      delete ridersClone.returnOfPremium;
      delete ridersClone.surrenderValueEnhancementRiderInfo;
      return ridersClone;
    }

    const fetchComparisionIllustration = values => {
      if (comparisonData?.SpecAmnt && specAmountGetted) {
        setComparisonData({
          BalanceSolve: null,
          MinNonMec: null,
          SpecAmnt: comparisonData.SpecAmnt,
        });
      } else {
        setComparisonData(comparasionDataInitValues);
      }
      const minNonMecObj = MapValuesToApi(
        values,
        settings.serverDateTime,
        startValues,
      );

      //we force the Min Non Mec in Specified Amount always 
      minNonMecObj.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue =
        FASE_AMOUNT_PREMIUM.MinNonMec.value;

      const balanceSolveObj = MapValuesToApi(
        values,
        settings.serverDateTime,
        startValues,
      );

      let serverError = 0;

      if (
        balanceSolveObj.faceAmountAndPremium.totalSpecifiedAmount.length > 0
      ) {
        balanceSolveObj.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue =
          FASE_AMOUNT_PREMIUM.BalanceSolve.value;
      }

      minNonMecObj.riders = cleanRidersToSimulate(minNonMecObj.riders);
      balanceSolveObj.riders = cleanRidersToSimulate(balanceSolveObj.riders);

      getComparisonIllustration(minNonMecObj)
        .then(response => {
          setComparisonData(prev => ({ ...prev, MinNonMec: response.data }));
        })
        .catch((error) => {
          if(error.status && error.status == HTTP_STATUS.Canceled) {
            return;
          }
          setStatusResultType(RESULT_MESSAGES_ERROR);
          serverError++;
          if (serverError === 1) {
            setMinNonMecErrors(serverError);
            setServerResultMessages([
              {
                MessageId: '1',
                userMessage: error.response.data[0].userMessage || REQUEST_ERROR_MESSAGE,
              },
            ]);
            setIsTablePopupOpen(false);
            setIsNotificationDisplayed(true);
          }
        }).finally(() => setMinNonMecErrors(0));

      getComparisonIllustration(balanceSolveObj)
        .then(response => {
          setComparisonData(prev => ({
            ...prev,
            BalanceSolve: response.data,
          }));
        })
        .catch((error) => {
          if(error.status && error.status == HTTP_STATUS.Canceled) {
            return;
          }
          setStatusResultType(RESULT_MESSAGES_ERROR);
          serverError++;
          if (serverError === 1) {
            setMinNonMecErrors(serverError);
            setServerResultMessages([
              {
                MessageId: '1',
                userMessage: error.response.data[0].userMessage || REQUEST_ERROR_MESSAGE,
              },
            ]);
            setIsTablePopupOpen(false);
            setIsNotificationDisplayed(true);
          }
        }).finally(() => setMinNonMecErrors(0));
    };

    async function validateSession() {
      if(!auth.isAuthenticated()) {
        return false;
      }
      return true;
    }

    const handleOnSubmit = async values => {
        setMinNonMecErrors(0);
        if (values.faceAmountAndPremium.totalSpecifiedAmount.length === 1
            && !isbuttonWasClicked 
            && isNaN(values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue)
            && (values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue.toUpperCase() === FASE_AMOUNT_PREMIUM.MinNonMec.value.toUpperCase() 
            || values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue.toUpperCase() === FASE_AMOUNT_PREMIUM.BalanceSolve.value.toUpperCase())) {
            setRadioButtonValue(values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue);
            fetchComparisionIllustration(values);
            setIllustrationValues(values);
            if (!minNonMecErrors) {
                setIsTablePopupOpen(true);
            }
            return;
        }
        const deepClonedObject = MapValuesToApi(
            values,
            settings.serverDateTime,
            startValues,
        );

        if (values.faceAmountAndPremium.totalSpecifiedAmount.length === 1
            && isNaN(values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue) 
            && values.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue.toUpperCase() === FASE_AMOUNT_PREMIUM.MinNonMec.value.toUpperCase()
            && radioButtonValue.toUpperCase() !== FASE_AMOUNT_PREMIUM.MinNonMec.value.toUpperCase()
            && radioButtonValue !== ''
        ) {
            deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[0] = totalSpecifiedAmountCleanObject;
            deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue = 
                radioButtonValue.toUpperCase() === FASE_AMOUNT_PREMIUM.SpecAmount.value.toUpperCase() ? specifiedAmountValue : radioButtonValue;
        }

        if (radioButtonValue.toUpperCase() === FASE_AMOUNT_PREMIUM.MinNonMec.value.toUpperCase()) {
          deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue = FASE_AMOUNT_PREMIUM.MinNonMec.value;
        } else if (
          radioButtonValue.toUpperCase() === FASE_AMOUNT_PREMIUM.BalanceSolve.value.toUpperCase()
        ) {
          deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue = FASE_AMOUNT_PREMIUM.BalanceSolve.value;
        } else if (specifiedAmountValue) {
          deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue = specifiedAmountValue;
        }
          
        let newTab;
        const isValidSession = await validateSession();
        setIsLoading(true);
        if (!isMobileOnly && isValidSession) { 
          newTab = window.open(`${process.env.REACT_APP_SPA_URL}/illustration/pdfLoader`); 
        }
        saveIllustration(deepClonedObject)
            .then(response => {
                setStatusResultType(RESULT_MESSAGES_SUCCESS);
                setServerResultMessages(response.data.messages);
                setIsNotificationDisplayed(true);
                setIsLoading(false);
                setRadioButtonValue('');
                setIllustrationValues({});
                setIsbuttonWasClicked(false);
                if(specifiedAmountValue)
                  setSpecifiedAmountValue(null);
                const pdfURL = `${process.env.REACT_APP_API_URL}/api/Illustrations/${response.data.pdfGuid}/pdf`;
                const pdfViewerURL = `${process.env.REACT_APP_SPA_URL}/illustration/viewPDF/${response.data.pdfGuid}`;
                if(!isMobileOnly){
                    newTab.location.href = pdfViewerURL
                }else{
                  window.open(pdfURL);
                }
                history.push(`/Illustration/${response.data.internalIllustrationId}`);
                setIllustrationId(response.data.internalIllustrationId);
            })
            .catch(error => {
                if (newTab) {
                  newTab.close();
                }
                setIsbuttonWasClicked(false);
                try {
                  if (error && error.response) {
                    const { data, status } = error.response;
                    setIsLoading(false);
                    setStatusResultType(RESULT_MESSAGES_ERROR);
                    if (_.isObject(data) && status === 400) {
                      setServerResultMessages([
                        {
                          MessageId: '1',
                          userMessage: SERVER_ERROR_MESSAGE,
                        },
                      ]);
                    } else {
                      setServerResultMessages(data);
                    }
                    setIsNotificationDisplayed(true);
                  }
                  else {
                    setStatusResultType(RESULT_MESSAGES_ERROR);
                    setServerResultMessages([
                      {
                        MessageId: '1',
                        userMessage: REQUEST_ERROR_MESSAGE,
                      },
                    ]);
                    setIsNotificationDisplayed(true);
                  }
                } catch (err) {
                    setStatusResultType(RESULT_MESSAGES_ERROR);
                    setServerResultMessages([
                        {
                            MessageId: '1',
                            userMessage: REQUEST_ERROR_MESSAGE,
                        },
                    ]);
                    setIsNotificationDisplayed(true);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOnMinNonMecComparisonChange = value => setRadioButtonValue(value);
    
    const fetchComparisionIllustrationBySpecifiedAmount = async deathBenefitValue => {
      setSpecifiedAmountValue(deathBenefitValue);
      const illustrationObj = MapValuesToApi(
        illustrationValues,
        settings.serverDateTime,
        startValues,
      );

      illustrationObj.faceAmountAndPremium.totalSpecifiedAmount[0] = totalSpecifiedAmountCleanObject;
      illustrationObj.faceAmountAndPremium.totalSpecifiedAmount[0].specifiedAmountValue = deathBenefitValue;

      illustrationObj.riders = cleanRidersToSimulate(illustrationObj.riders);

      await getComparisonIllustration(illustrationObj)
        .then(response => {
          setComparisonData(prev => ({ ...prev, SpecAmnt: response.data }));
          setSpecAmountGetted(true);
        })
        .catch(error => {
          if(error.status && error.status == HTTP_STATUS.Canceled) {
            return;
          }
          try {
            if (error && error.response) {
              const { data, status } = error.response;
              setStatusResultType(RESULT_MESSAGES_ERROR);
              if (_.isObject(data) && status === 400) {
                setServerResultMessages([
                  {
                    MessageId: '1',
                    userMessage: error.response.data[0].userMessage || SERVER_ERROR_MESSAGE,
                  },
                ]);
              } else {
                setServerResultMessages(data);
              }
              setIsTablePopupOpen(false);
              setIsNotificationDisplayed(true);
            }
            else {
              setStatusResultType(RESULT_MESSAGES_ERROR);
              setServerResultMessages([
                {
                  MessageId: '1',
                  userMessage: REQUEST_ERROR_MESSAGE,
                },
              ]);
              setIsTablePopupOpen(false);
              setIsNotificationDisplayed(true);
            }
          } catch (err) {
            setIsTablePopupOpen(false);
            setStatusResultType(RESULT_MESSAGES_ERROR);
            setServerResultMessages([
              {
                MessageId: '1',
                userMessage: REQUEST_ERROR_MESSAGE,
              },
            ]);
            setIsNotificationDisplayed(true);
          }
          setComparisonData({
            BalanceSolve: null,
            MinNonMec: null,
            SpecAmnt: {responseSpecifiedAmount: initialValues.illustrationIndicators.deathBenefitAmount},
          });
          setSpecAmountGetted(false);
        });
    };

    return (
      <>
        <FormWizard
                settings={settings}
                initialValues={initialValues}
                handleSubmit={handleOnSubmit}
                validations={[
                    InsuredValidations,
                    FaceAmountValidations,
                    InterestRateValidations,
                    RidersValidations,
                    OutputDesignValidations,
                ]}
            >
          {({
                    currentStep,
                    handleNext,
                    handlePrev,
                    handleSubmit,
                }) => (
                  <>
                    <FormWizard.Navigation>
                      <FormWizard.Header>
                        <Typography variant="h1" color="primary">
                          {!illustrationId
                                        ? intl.formatMessage(messages.wizardNewTitle)
                                        : intl.formatMessage(messages.wizardEditTitle)}
                        </Typography>
                      </FormWizard.Header>
                      {stepTitles.map((stepTitle, index) => (
                        <FormWizard.NavigationStep key={stepTitle} step={index + 1}>
                          {stepTitle}
                        </FormWizard.NavigationStep>
                            ))}
                    </FormWizard.Navigation>
                    <FormWizard.Steps>
                      <FormWizard.Step step={1}>
                        <FormWizard.StepTitle>
                          <Typography variant="h2" color="textSecondary">
                            {intl.formatMessage(messages.insuredInformation)}
                            <Hint
                                            top={-1}
                                            isInTitle
                                            position="right"
                                            text={
                                              <FormattedHTMLMessage
                                                    {...messages.insuredInformationHint}
                                                />
                                            }
                                        />
                          </Typography>
                        </FormWizard.StepTitle>
                        <InsuredStep />
                      </FormWizard.Step>
                      <FormWizard.Step step={2}>
                        <FormWizard.StepTitle>
                          <Typography variant="h2" color="textSecondary">
                            {intl.formatMessage(messages.faceAmount)}
                            <Hint
                                            top={-2}
                                            isInTitle
                                            position="right"
                                            text={
                                              <FormattedHTMLMessage {...messages.faceAmountHint} />
                                            }
                                        />
                          </Typography>
                        </FormWizard.StepTitle>
                        <FaceAmount />
                      </FormWizard.Step>
                      <FormWizard.Step step={3}>
                        <FormWizard.StepTitle>
                          <Typography variant="h2" color="textSecondary">
                            {intl.formatMessage(messages.interestRate)}
                            <Hint
                                            top={-2}
                                            isInTitle
                                            position="right"
                                            text={
                                              <FormattedHTMLMessage {...messages.interestRateHint} />
                                            }
                                        />
                          </Typography>
                        </FormWizard.StepTitle>
                        <InterestRate />
                      </FormWizard.Step>
                      <FormWizard.Step step={4}>
                        <FormWizard.StepTitle>
                          <Typography
                                        variant="h2"
                                        color="textSecondary"
                                        className="rider-tooltip"
                                    >
                            {intl.formatMessage(messages.riderOptions)}
                          </Typography>
                        </FormWizard.StepTitle>
                        <Riders />
                      </FormWizard.Step>
                      <FormWizard.Step step={5}>
                        <FormWizard.StepTitle>
                          <Typography variant="h2" color="textSecondary">
                            {intl.formatMessage(messages.basicIllustration)}
                            <Hint
                                            top={-2}
                                            isInTitle
                                            position="right"
                                            text={<FormattedHTMLMessage {...messages.outputHint} />}
                                        />
                          </Typography>
                        </FormWizard.StepTitle>
                        <OutputDesign />
                      </FormWizard.Step>
                    </FormWizard.Steps>
                    <NotificationMessages
                            isOpen={isNotificationDisplayed}
                            messages={serverResultMessages}
                            onHandleClose={handleShowNotification}
                            notificationType={statusResultType}
                        />
         
                    <FormWizard.NavigationControls>
                      <>
                        <FormWizard.Next>
                          <Button
                                        type="button"
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleNext}
                                    >
                            {intl.formatMessage(messages.next)}
                          </Button>
                        </FormWizard.Next>
                        <FormWizard.Submit>
                          <ButtonProgress
                                        type="button"
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleNext}
                                        loading={isLoading}
                                    >
                            {intl.formatMessage(messages.runIllustration)}
                          </ButtonProgress>
                        </FormWizard.Submit>
                        <FormWizard.Previous>
                          <Button type="button" variant="outlined" color="primary" onClick={handlePrev}>
                            {intl.formatMessage(messages.back)}
                          </Button>
                        </FormWizard.Previous>
                        {currentStep === 5 && (
                        <PopupWarningText>
                          <FormattedHTMLMessage {...messages.popupMessageWarning} />
                        </PopupWarningText>
                                )}
                        {isTablePopupOpen && (
                        <MinNonMECComparison 
                                        ValidateSavePopup={ValidateSavePopup} 
                                        ValidateCancelPopup={ValidateCancelPopup} 
                                        open={isTablePopupOpen} 
                                        handleSubmit={handleSubmit} 
                                        data={comparisonData}
                                        handleOnChange={handleOnMinNonMecComparisonChange}
                                        radioButtonsValue={radioButtonValue}
                                        fetchDeathBenData={fetchComparisionIllustrationBySpecifiedAmount}
                                        specAmountGetted={specAmountGetted}
                                        increaseInitialDeathBen={illustrationValues.faceAmountAndPremium.increaseInitialDBbyXChk}
                                        cancelationComparisonRequest={cancelComparisonIllustrationRequest}/>
                                )}
                      </>
                    </FormWizard.NavigationControls>
                  </>
                )}

        </FormWizard>
      </>
    );
};

Illustration.propTypes  = {
    match: PropTypes.any,
    history: PropTypes.any,
};

export default Illustration;
