import _ from 'lodash';
import { removeCharacter, isPercentFormat } from '../../../utils/utils';
import { ageCalculation } from '../../../utils/ageCalculation';
import { RISK_CLASS_STANDARD_NON_TABACCO, TEEN_AGE } from '../../../constants';

const MapDataValuesToState = (values, initialValues, settings) => {
  const deepClonedObject = _.cloneDeep(values.data);

  // Illustration title
  deepClonedObject.illustrationTitle =
    values.illustrationTitle || initialValues.illustrationTitle;

  //INSURED
  if (!deepClonedObject.insured.financePremiums) {
    deepClonedObject.insured.financePremiums = false;
  }
  deepClonedObject.insured.rating =
    deepClonedObject.insured.rating || initialValues.insured.rating;
  if (!deepClonedObject.insured.rating) {
    deepClonedObject.insured.flatExtra = initialValues.insured.flatExtra;
  }
  const currentAge = ageCalculation(deepClonedObject.insured.issueAgeOrDateOfBirth, settings);
  if (currentAge !== null) {
    let riskClassesAvailable = settings.riskClasses.filter(item => {
      return  (currentAge >= TEEN_AGE && item.maxAge >= currentAge) || (currentAge < TEEN_AGE && item.value === RISK_CLASS_STANDARD_NON_TABACCO )
    });

    let isCurrentRiskClassAvailable = true;
    isCurrentRiskClassAvailable = riskClassesAvailable.find(item => {
      return  item.value === deepClonedObject.insured.riskClass
    });

    if (!isCurrentRiskClassAvailable) {
      deepClonedObject.insured.riskClass = undefined;
    }      
  }

  if (
    deepClonedObject.insured.riskClass !== 'Standard Tobacco' &&
    deepClonedObject.insured.riskClass !== 'Standard Nontobacco'
  ) {
    deepClonedObject.insured.rating = initialValues.insured.rating;
    deepClonedObject.insured.tableRating = initialValues.insured.tableRating;
    deepClonedObject.insured.flatExtra = initialValues.insured.flatExtra;
  }

  //FACE AMOUNT
  deepClonedObject.faceAmountAndPremium.increaseInitialDBbyXChk = 
    !!deepClonedObject.faceAmountAndPremium.increaseInitialDBbyX
  deepClonedObject.faceAmountAndPremium.section7702 =
    deepClonedObject.faceAmountAndPremium.section7702 ||
    initialValues.faceAmountAndPremium.section7702;
  deepClonedObject.faceAmountAndPremium.reduceSpecifiedAmount =
    deepClonedObject.faceAmountAndPremium.reduceSpecifiedAmount ||
    initialValues.faceAmountAndPremium.reduceSpecifiedAmount;
  deepClonedObject.faceAmountAndPremium.premiumMode =
    deepClonedObject.faceAmountAndPremium.premiumMode ||
    initialValues.faceAmountAndPremium.premiumMode;
  deepClonedObject.faceAmountAndPremium.monthToBeginScheduledPremium =
    deepClonedObject.faceAmountAndPremium.monthToBeginScheduledPremium ||
    initialValues.faceAmountAndPremium.monthToBeginScheduledPremium;
  deepClonedObject.faceAmountAndPremium.lumpSumMonthFirstYear =
    deepClonedObject.faceAmountAndPremium.lumpSumMonthFirstYear ||
    initialValues.faceAmountAndPremium.lumpSumMonthFirstYear;

  deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo =
    deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo ||
    initialValues.faceAmountAndPremium.advancedPremiumFundInfo;
  if (deepClonedObject.faceAmountAndPremium.advancedPremiumFund) {
    const advancedPremiumFundAmount =
      deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo
        .advancedPremiumFundAmount;
    deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo.advancedPremiumFundAmount = removeCharacter(
      '$',
      advancedPremiumFundAmount,
    );
    deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo.discountRate =
      deepClonedObject.faceAmountAndPremium.advancedPremiumFundInfo.discountRate ||
      initialValues.faceAmountAndPremium.advancedPremiumFundInfo.discountRate;
  }

  deepClonedObject.faceAmountAndPremium.targetCashValue =
    deepClonedObject.faceAmountAndPremium.targetCashValue ||
    initialValues.faceAmountAndPremium.targetCashValue;
  deepClonedObject.faceAmountAndPremium.targetDeathBenefitYear =
    deepClonedObject.faceAmountAndPremium.targetDeathBenefitYear ||
    initialValues.faceAmountAndPremium.targetDeathBenefitYear;
  deepClonedObject.faceAmountAndPremium.targetYear =
    deepClonedObject.faceAmountAndPremium.targetYear ||
    initialValues.faceAmountAndPremium.targetYear;
  deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount =
    deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount ||
    initialValues.faceAmountAndPremium.totalSpecifiedAmount;
  deepClonedObject.faceAmountAndPremium.scheduledPremium =
    deepClonedObject.faceAmountAndPremium.scheduledPremium ||
    initialValues.faceAmountAndPremium.scheduledPremium;
  deepClonedObject.faceAmountAndPremium.deathBenefitOption =
    deepClonedObject.faceAmountAndPremium.deathBenefitOption ||
    initialValues.faceAmountAndPremium.deathBenefitOption;
  deepClonedObject.faceAmountAndPremium.lumpSumPremium =
    deepClonedObject.faceAmountAndPremium.lumpSumPremium ||
    initialValues.faceAmountAndPremium.lumpSumPremium;

  deepClonedObject.faceAmountAndPremium._1035Exchange =
    deepClonedObject.faceAmountAndPremium['1035Exchange'];
  deepClonedObject.faceAmountAndPremium.exchangeInfo =
    initialValues.faceAmountAndPremium.exchangeInfo;

  if (deepClonedObject.faceAmountAndPremium['1035Exchange']) {
    deepClonedObject.faceAmountAndPremium.exchangeInfo =
      deepClonedObject.faceAmountAndPremium['1035ExchangeInfo'];
    deepClonedObject.faceAmountAndPremium.exchangeInfo.loanBalance1035 =
      deepClonedObject.faceAmountAndPremium['1035ExchangeInfo'][
        '1035LoanBalance'
      ];
  }

  _.forEach(
    deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount,
    function(element, i) {
      element.max =
        deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[i].max ||
        initialValues.faceAmountAndPremium.totalSpecifiedAmount[0].max;
      element.step =
        deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[i].step ||
        initialValues.faceAmountAndPremium.totalSpecifiedAmount[0].step;
      element.increase =
        deepClonedObject.faceAmountAndPremium.totalSpecifiedAmount[i]
          .increase ||
        initialValues.faceAmountAndPremium.totalSpecifiedAmount[0].increase;
    },
  );

  _.forEach(deepClonedObject.faceAmountAndPremium.scheduledPremium, function(
    element,
    i,
  ) {
    element.max =
      deepClonedObject.faceAmountAndPremium.scheduledPremium[i].max ||
      initialValues.faceAmountAndPremium.scheduledPremium[0].max;
    element.step =
      deepClonedObject.faceAmountAndPremium.scheduledPremium[i].step ||
      initialValues.faceAmountAndPremium.scheduledPremium[0].step;
    element.increase =
      deepClonedObject.faceAmountAndPremium.scheduledPremium[i].increase ||
      initialValues.faceAmountAndPremium.scheduledPremium[0].increase;
  });

  //INTEREST RATE AND INCOME

  deepClonedObject.interestRateAndIncome.premiumAllocation =
    deepClonedObject.interestRateAndIncome.premiumAllocation ||
    initialValues.interestRateAndIncome.premiumAllocation;
  deepClonedObject.interestRateAndIncome.maturedSegmentAllocation =
    deepClonedObject.interestRateAndIncome.maturedSegmentAllocation ||
    initialValues.interestRateAndIncome.maturedSegmentAllocation;
  deepClonedObject.interestRateAndIncome.fixedCreditingRate =
    deepClonedObject.interestRateAndIncome.fixedCreditingRate ||
    initialValues.interestRateAndIncome.fixedCreditingRate;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_1YrCappedSP500'
  ] =
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies['1-YrCappedS&P500'] ||
    initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrCappedSP500;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_2YrSP500'
  ] =
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies['2-YrS&P500'] ||
    initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrSP500;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_1YrJPMorganMozaicII'
  ] =
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies['1-YrJPMorganMozaicII'] ||
    initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrJPMorganMozaicII;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_2YrJPMorganMozaicII'
  ] =
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies['2-YrJPMorganMozaicII'] ||
    initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrJPMorganMozaicII;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_1YrNYSEZebraEdge'
  ] =
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies['1-YrNYSEZebraEdge'] ||
    initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrNYSEZebraEdge;
  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_2YrNYSEZebraEdge'
  ] =
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies['2-YrNYSEZebraEdge'] ||
    initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrNYSEZebraEdge;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_1YrHighPar5GoldmanSachsNewHorizons'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrHighPar5GoldmanSachsNewHorizons'
    ] ||
  initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrHighPar5GoldmanSachsNewHorizons;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_1YrGoldmanSachsNewHorizons'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '1-YrGoldmanSachsNewHorizons'
    ] ||
  initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._1YrGoldmanSachsNewHorizons;

  deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '_2YrGoldmanSachsNewHorizons'
  ] =
    deepClonedObject.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies[
    '2-YrGoldmanSachsNewHorizons'
    ] ||
  initialValues.interestRateAndIncome.coreNewHeightsIndexedInterestStrategies
      ._2YrGoldmanSachsNewHorizons;

  deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '_1YrChoicePlusJPMorganMozaicII'
  ] =
    deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
      '1-YrChoicePlusJPMorganMozaicII'
    ] ||
    initialValues.interestRateAndIncome.choicePlusIndexedStrategies
      ._1YrChoicePlusJPMorganMozaicII;
  deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '_1YrChoicePlusNYSEZebraEdge'
  ] =
    deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
      '1-YrChoicePlusNYSEZebraEdge'
    ] ||
    initialValues.interestRateAndIncome.choicePlusIndexedStrategies
      ._1YrChoicePlusNYSEZebraEdge;

  // Remove Index Interest strategies
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['1-YrCappedS&P500'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['2-YrS&P500'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['1-YrJPMorganMozaicII'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['2-YrJPMorganMozaicII'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['1-YrNYSEZebraEdge'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['2-YrNYSEZebraEdge'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['1-YrHighPar5GoldmanSachsNewHorizons'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['1-YrGoldmanSachsNewHorizons'];
  delete deepClonedObject.interestRateAndIncome
    .coreNewHeightsIndexedInterestStrategies['2-YrGoldmanSachsNewHorizons'];

  // Remove Plus Index strategies
  delete deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '1-YrChoicePlusJPMorganMozaicII'
  ];
  delete deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '1-YrChoicePlusNYSEZebraEdge'
  ];
  delete deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies[
    '1-YrChoicePlusCappedS&P500'
  ];

  _.forEach(
    deepClonedObject.interestRateAndIncome.incomeDistributionAmounts,
    function(element, i) {
      element.max =
        deepClonedObject.interestRateAndIncome.incomeDistributionAmounts[i]
          .max ||
        initialValues.interestRateAndIncome.incomeDistributionAmounts[0].max;
      element.step =
        deepClonedObject.interestRateAndIncome.incomeDistributionAmounts[i]
          .step ||
        initialValues.interestRateAndIncome.incomeDistributionAmounts[0].step;
      element.increase =
        deepClonedObject.interestRateAndIncome.incomeDistributionAmounts[i]
          .increase ||
        initialValues.interestRateAndIncome.incomeDistributionAmounts[0]
          .increase;
    },
  );

  let validIndexes = settings.maturedSegmentAllocations.map(index => index.value);
  _.forEach(
    deepClonedObject.interestRateAndIncome.maturedSegmentAllocation,
    function(element) {
      let tot = 0;
      // Remove any unsupported index
      const currentSavedIndexes = {};
      element.indexInfo = element.indexInfo.filter(
        indexItem => {
          const isValidIndex = _.includes(validIndexes, indexItem.key)
          if (isValidIndex) {
            currentSavedIndexes[indexItem.key] = indexItem;
            return true;
          }
          return false;
        }
      );

      element.indexInfo = validIndexes.map(indexName => {
        let allocation = null;
        if (currentSavedIndexes[indexName] !== undefined) {
          allocation = currentSavedIndexes[indexName].allocation || null;
        } 

        return {
          key: indexName,
          allocation
        };
      })

      _.forEach(element.indexInfo, function(prop) {
        if (prop.allocation) tot = tot + prop.allocation;
      });
      element.total = tot;
    },
  );

  validIndexes = settings.premiumAllocations.map(index => index.value);
  _.forEach(deepClonedObject.interestRateAndIncome.premiumAllocation, function(
    element
  ) {
    let tot = 0;
    // Remove any unsupported index
    element.indexInfo = element.indexInfo.filter(
      indexItem => _.includes(validIndexes, indexItem.key)
    );

    _.forEach(element.indexInfo, function(prop) {
      if (prop.allocation) tot = tot + prop.allocation;
    });
    element.total = tot;
  });

  deepClonedObject.interestRateAndIncome.fixedCreditingRate.forEach(element => {
    if (isPercentFormat(element.creditRate)) {
      let crdt = removeCharacter('%', element.creditRate);
      element.creditRate = parseFloat(crdt);
    }
  });

  _.forEach(
    deepClonedObject.interestRateAndIncome.choicePlusIndexedStrategies,
    function(element) {
      _.forEach(element, function(prop) {
        let isr = removeCharacter('%', prop.indexStrategyRate);
        prop.indexStrategyRate = parseFloat(isr);
      });
    },
  );

  _.forEach(
    deepClonedObject.interestRateAndIncome
      .coreNewHeightsIndexedInterestStrategies,
    function(element) {
      _.forEach(element, function(prop) {
        let isr = removeCharacter('%', prop.indexStrategyRate);
        prop.indexStrategyRate = parseFloat(isr);
      });
    },
  );

  // Has income
  deepClonedObject.interestRateAndIncome.hasIncome = values.hasIncome;
  // Income section
  if (deepClonedObject.interestRateAndIncome.hasIncome) {
    deepClonedObject.interestRateAndIncome.incomeDistributionAmounts =
      deepClonedObject.interestRateAndIncome.incomeDistributionAmounts ||
      initialValues.interestRateAndIncome.incomeDistributionAmounts;
    deepClonedObject.interestRateAndIncome.incomeLoanType =
      deepClonedObject.interestRateAndIncome.incomeLoanType ||
      initialValues.interestRateAndIncome.incomeLoanType;
    deepClonedObject.interestRateAndIncome.incomeWithdrawalCap =
      deepClonedObject.interestRateAndIncome.incomeWithdrawalCap ||
      initialValues.interestRateAndIncome.incomeWithdrawalCap;
    deepClonedObject.interestRateAndIncome.incomeDistributionMode =
      deepClonedObject.interestRateAndIncome.incomeDistributionMode ||
      initialValues.interestRateAndIncome.incomeDistributionMode;
    deepClonedObject.interestRateAndIncome.incomeLoanInterestPaymentMethod =
      deepClonedObject.interestRateAndIncome.incomeLoanInterestPaymentMethod ||
      initialValues.interestRateAndIncome.incomeLoanInterestPaymentMethod;
  } else {
    deepClonedObject.interestRateAndIncome.incomeDistributionAmounts =
      initialValues.interestRateAndIncome.incomeDistributionAmounts;
    deepClonedObject.interestRateAndIncome.incomeLoanType =
      initialValues.interestRateAndIncome.incomeLoanType;
    deepClonedObject.interestRateAndIncome.incomeWithdrawalCap =
      initialValues.interestRateAndIncome.incomeWithdrawalCap;
    deepClonedObject.interestRateAndIncome.incomeDistributionMode =
      initialValues.interestRateAndIncome.incomeDistributionMode;
    deepClonedObject.interestRateAndIncome.incomeLoanInterestPaymentMethod =
      initialValues.interestRateAndIncome.incomeLoanInterestPaymentMethod;
  }

  //Riders
  deepClonedObject.riders.longTermCareRiderII =
    deepClonedObject.riders.longTermCareRiderII ||
    initialValues.riders.longTermCareRiderII;

  if (deepClonedObject.riders.longTermCareRiderII) {
    deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo =
      deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo ||
      initialValues.riders.longTermCareRiderInfo.LTCAnalysisReportInfo;

    const hipaaPerDiemRate =
      deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo
        .hipaaPerDiemRate;
    if (deepClonedObject.riders.longTermCareRiderInfo.printLTCAnalysisRepot) {
      deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo.hipaaPerDiemRate = parseFloat(
        removeCharacter('%', hipaaPerDiemRate),
      );
    } else {
      deepClonedObject.riders.longTermCareRiderInfo.LTCAnalysisReportInfo.hipaaPerDiemRate = parseFloat(
        hipaaPerDiemRate,
      );
    }
  }

  deepClonedObject.riders.accidentalDeathBenefit =
    deepClonedObject.riders.accidentalDeathBenefit ||
    initialValues.riders.accidentalDeathBenefit;
  deepClonedObject.riders.adbAmountAndRating =
    deepClonedObject.riders.adbAmountAndRating ||
    initialValues.riders.adbAmountAndRating;

  if (deepClonedObject.riders.accidentalDeathBenefit) {
    deepClonedObject.riders.adbAmountAndRating.ADBAmount = removeCharacter(
      '$',
      deepClonedObject.riders.adbAmountAndRating.ADBAmount,
    );
  }

  deepClonedObject.riders.waiverRider =
    deepClonedObject.riders.waiverRider || initialValues.riders.waiverRider;
  deepClonedObject.riders.waiverRiderInfo =
    deepClonedObject.riders.waiverRiderInfo ||
    initialValues.riders.waiverRiderInfo;

  if(!deepClonedObject.riders.surrenderValueEnhancementRider) {
    deepClonedObject.riders.surrenderValueEnhancementRider = initialValues.riders.surrenderValueEnhancementRider;
    deepClonedObject.riders.surrenderValueEnhancementRiderInfo = {
      returnOfPremium: initialValues.riders.surrenderValueEnhancementRiderInfo.returnOfPremium,
    };
  }
    
  if (
    deepClonedObject.riders.waiverRider &&
    deepClonedObject.riders.waiverRiderInfo.waiverType !== 'Premium Waiver'
  ) {
    deepClonedObject.riders.waiverRiderInfo.premiumAmount =
      initialValues.riders.waiverRiderInfo.premiumAmount;
  }

  deepClonedObject.riders.conditionalReturnOfPremium =
    deepClonedObject.riders.conditionalReturnOfPremium ||
    initialValues.riders.conditionalReturnOfPremium;
  deepClonedObject.riders.returnOfPremium =
    deepClonedObject.riders.returnOfPremium ||
    initialValues.riders.returnOfPremium;
  deepClonedObject.riders.longTermCareRiderInfo =
    deepClonedObject.riders.longTermCareRiderInfo ||
    initialValues.riders.longTermCareRiderInfo;

  if (!deepClonedObject.riders.changeOfInsuredRiderInfo) {
    deepClonedObject.riders.changeOfInsuredRiderInfo = false;
  }

  //Output.
  deepClonedObject.presentedBy = values.preparedBy || '';
  delete deepClonedObject.advisorDetails;

  //Illustration Indicators
  if (!deepClonedObject.illustrationIndicators.monthlyCostSummary) {
    deepClonedObject.illustrationIndicators.whichIllustration =
      initialValues.illustrationIndicators.whichIllustration;
    deepClonedObject.illustrationIndicators.yearsForMonthlyOutput =
      initialValues.illustrationIndicators.yearsForMonthlyOutput;
  }
  if (!deepClonedObject.illustrationIndicators.netCostPerDollar) {
    deepClonedObject.illustrationIndicators.costOfMoney =
      initialValues.illustrationIndicators.costOfMoney;
  }
  if (!deepClonedObject.illustrationIndicators.rangeOfPossibleOutcomes) {
    deepClonedObject.illustrationIndicators.rangeOfPossibleOutcomes =
      initialValues.illustrationIndicators.rangeOfPossibleOutcomes;
  }
  deepClonedObject.illustrationIndicators.deathBenefitAmount =
    initialValues.illustrationIndicators.deathBenefitAmount;

  if (!deepClonedObject.illustrationIndicators.indexInterestStrategyInformation) {
    deepClonedObject.illustrationIndicators.indexInterestStrategyInformation = false;
  }

  return deepClonedObject;
};

export default MapDataValuesToState;
